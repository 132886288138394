.scrolable-div {
  overflow-y: auto;
}
/* width */
.scrolable-div::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.scrolable-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrolable-div::-webkit-scrollbar-thumb {
  background: #32c0cc59;
}

/* Handle on hover */
.scrolable-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-separator {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0 auto;
  color: #f5934b;
  font-size: 16px;
  /* line-height: 50px; */
  text-align: center;
}

.content-separator::before,
.content-separator::after {
  position: absolute;
  width: 290px;
  height: 1px;
  /* top: 24px; */
  background-color: #fbcf5d;
  content: "";
}

.content-separator::before {
  left: 0;
}

.content-separator::after {
  right: 0;
}
.winner_card:hover {
  transform: scale(1.02);
  transition: 0.8s;
  box-shadow: 0px 0px 10px 1px #00000021 !important;
}
.winner-gold-award img {
  width: 100px;
}

.winner-gold-award img {
  /* animation: pulse 1s linear infinite; */
  animation-name: pulse_animation;
  animation-duration: 3s;
  transform-origin: 70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes pulse_animation {
  /* 0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  } */
  0% {
    transform: scale(1);
  }
  8% {
    transform: scale(1.01);
  }
  16% {
    transform: scale(1.02);
  }
  24% {
    transform: scale(1.03);
  }
  32% {
    transform: scale(1.04);
  }
  40% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1.06);
  }
  58% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(1.04);
  }
  74% {
    transform: scale(1.03);
  }
  82% {
    transform: scale(1.02);
  }
  90% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

.winner-silver-award img {
  width: 60px;
}
.quiz-medal {
  position: absolute;
  margin-bottom: 16px;
  top: 10px;
}

.quiz-medal__circle {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: white;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  position: relative;
  border-width: 0.2em;
  border-style: solid;
  z-index: 1;
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 2px 2px 0 #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle_lg {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  line-height: inherit;
  justify-content: center;
}
.quiz-medal__circle.quiz-medal__circle--gold {
  box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #fadd40;
  text-shadow: 0 0 4px #9d6c04;
  background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}
.quiz-medal__circle.quiz-medal__circle--silver {
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 0px 0px 4px #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle.quiz-medal__circle--bronze {
  box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #f7bb23;
  text-shadow: 0 0 4px #7d4604;
  background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}
.quiz-medal__ribbon {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 6px 8px;
  width: 0;
  height: 10px;
  top: 38px;
}
.quiz-medal__ribbon--left {
  border-color: #fc402d #fc402d transparent #fc402d;
  left: 4px;
  transform: rotate(20deg) translateZ(-32px);
}
.quiz-medal__ribbon--right {
  left: 22px;
  border-color: #f31903 #f31903 transparent #f31903;
  transform: rotate(-20deg) translateZ(-48px);
}
@media screen and (max-width: 767px) {
  .content-separator::before,
  .content-separator::after {
    width: 160px;
  }
  .title h1 {
    font-size: 29px;
    margin: 15px 0;
    text-align: left;
  }
  .title {
    margin: 0;
  }
}
