/* .show {
    display: flex !important;
    opacity: 1;
} */
/* .chl-topic{
    	margin-bottom: 10px;
	} */
/* .chlngr-count{
		text-align: center;
	}
	.chlngr-count .chl-count{
		font-size: 1.6rem;
	    line-height: 1.5rem;
	    letter-spacing: 0.4px;
	    color: #a2a8b1;
	    text-transform: uppercase;
	}
	.frnd-name{
		margin-bottom: 10px;
	}
	.user-video{
		margin-bottom:0px;
		line-height: 0px;
	}
	ul.vid-action li {
	    margin: 0px;
	    display: inline-flex;
	    width: auto;
	    border: 0px;
	} */
/* .chat-rooms li{
		padding: 0px !important;
	} */
/* #slider {
  position: relative;
    width: calc(100% - 30%);
    height: 27vw;
    font-family: 'Helvetica Neue', sans-serif;
    perspective: 1400px;
    transform-style: preserve-3d;
    left: 15%;
}

input[type=radio] {
  position: relative;
  top: 108%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  opacity: 0.4;
  transform: translateX(-83px);
  cursor: pointer;
}


input[type=radio]:nth-child(5) {
  margin-right: 0px;
}

input[type=radio]:checked {
  opacity: 1;
}




#slider label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: white;
  font-size: 70px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: transform 400ms ease;
  overflow: hidden;
} */
/* Slider Functionality */

/* Active Slide */
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
    box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
    transform: translate3d(0%, 0, 0px);
}

/* Next Slide */
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(15%, 0, -100px);
}

/* Next to Next Slide */
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    transform: translate3d(30%, 0, -250px);
}

/* Previous to Previous Slide */
#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    transform: translate3d(-30%, 0, -250px);
}

/* Previous Slide */
#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
    transform: translate3d(-15%, 0, -100px);
}

.ak-groups-top {
    display: inline-block;
    list-style: outside none none;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}
.ak-groups-top > li {
    border-bottom: 1px dashed #c2c2ce;
    margin-bottom: 13px;
    padding-bottom: 15px;
    display: flex;
}
.ak-groups-top .your-grp > a.join-btn {
    color: #92929e;
    display: inline-block;
    font-size: 12px;
    width: auto;
}
.ak-groups-top .your-grp h5 {
    line-height: 28px;
}
.join-btn {
    background: #deebf3 none repeat scroll 0 0;
    border-radius: 5px;
    font-size: 12px;
    padding: 4px 14px;
    transition: all 0.2s ease 0s;
}
a.say-hi {
    background: #deebf3 none repeat scroll 0 0;
    border: 1px solid #088dcd;
    border-radius: 100%;
    color: #088dcd;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    transition: all 0.2s ease 0s;
}
.upper-layer.vid-play-block {
    width: 100%;
    height: 100%;
    position: absolute;
}
.challenge-title {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    vertical-align: middle;
    width: 100%;
    position: relative;
}
.course {
    /*-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);*/
    position: relative;
    /*margin-top: 15px;*/
    border: 1px solid #eee;
    background: transparent;
    box-shadow: none;
}
.post-by > figure img {
    max-width: none;
}
.course-cat {
    width: 50%;
}
.ribbon-container {
    display: block;
    width: 6.5rem;
    height: 6.625rem;
    overflow: hidden;
    position: absolute;
    top: -1px;
    right: -1px;
}
.ribbon {
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.6667;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    padding: 0.3125rem 0;
    left: -0.6875rem;
    top: 1.563rem;
    width: 9.375rem;
    z-index: 10;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
    color: #fff !important;
    background-color: #2196f3;
}
.bg-2nd {
    background-color: #ff9800;
}
.bg-3rd {
    background-color: #f44336;
}
.bg-4th {
    background-color: #607d8b;
}
.pd-t-15 {
    padding-top: 15px;
}
.full-result-btn {
    color: #fff;
    display: inline-block;
    padding: 10px;
    text-align: center;
    width: 100%;
    font-weight: 500;
    border-radius: 5px;
    /* max-width: 55%; */
    border: 1px solid transparent;
    transition: all 0.2s linear 0s;
    background: #088dcd;
    font-size: 12px;
}
.full-result-btn:hover {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #088dcd;
    color: #088dcd;
}
.owl-prev,
.owl-next {
    left: -20px;
}
.rec-group {
    border-radius: 10px;
    border: 1px solid #eee;
    padding: 10px;
}
a.date {
    border-radius: 5px;
    color: #fff;
    float: right;
    height: 25px;
    margin-right: 0px;
    padding: 5px;
    text-align: center;
    vertical-align: none;
    width: 120px;
    font-size: 10.5px;
}
.date.chl-remain-days {
    border-radius: 5px !important;
    color: #fff !important;
    float: right !important;
    height: 25px !important;
    margin-right: 0px !important;
    padding: 5px !important;
    text-align: center !important;
    vertical-align: none !important;
    width: 120px !important;
    font-size: 10.5px !important;
}
.post-by {
    width: 60% !important;
}
.blog-grid .blog-title {
    width: 72%;
}
/*.slider {
          width: 50%;
          margin: auto;
      }*/

.slick-slide {
    margin: 0px 20px;
}

/* .slick-slide img {
    width: 100%;
} */
.slick-prev {
    left: -10px;
}
.slick-next {
    right: -10px;
}

.slick-prev:before,
.slick-next:before {
    color: black;
}

.slick-slide {
    transition: all ease-in-out 0.3s;
    opacity: 1;
}

.slick-active {
    opacity: 1;
}

.slick-current {
    opacity: 1;
}
.quiz-medal {
    position: absolute;
    margin-bottom: 16px;
}

.quiz-medal .left {
    left: 10px;
}

.quiz-medal__circle {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 500;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: white;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    position: relative;
    border-width: 0.2em;
    border-style: solid;
    z-index: 1;
    box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #edeff1;
    text-shadow: 2px 2px 0 #98a6ad;
    background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle.quiz-medal__circle--gold {
    box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #fadd40;
    text-shadow: 0 0 4px #9d6c04;
    background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}
.quiz-medal__circle.quiz-medal__circle--silver {
    box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #edeff1;
    text-shadow: 0px 0px 4px #98a6ad;
    background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}
.quiz-medal__circle.quiz-medal__circle--bronze {
    box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
    border-color: #f7bb23;
    text-shadow: 0 0 4px #7d4604;
    background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}
.quiz-medal__ribbon {
    content: "";
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 6px 8px;
    width: 0;
    height: 10px;
    top: 38px;
}
.quiz-medal__ribbon--left {
    border-color: #fc402d #fc402d transparent #fc402d;
    left: 4px;
    transform: rotate(20deg) translateZ(-32px);
}
.quiz-medal__ribbon--right {
    left: 22px;
    border-color: #f31903 #f31903 transparent #f31903;
    transform: rotate(-20deg) translateZ(-48px);
}
#challenge-modal .modal-dialog {
    max-width: 100%;
    margin: 0;
    height: 100vh;
    position: fixed;
}
.modal-header {
    border: 0 none;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 5px;
}
.modal-header .close {
    background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
    border-radius: 100%;
    color: #000;
    display: inline-block;
    height: 30px;
    margin: 0;
    padding: 0;
    width: 30px;
    z-index: 44;
}
.modal-badge {
    right: 0;
    top: 0;
}
.commentbar > .user {
    position: relative;
}
.chal-title {
    display: inline-block;
}
.chal-count-and-time {
    float: right;
    font-size: 12px;
    margin-right: 25px;
}
.chal-span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #a2a8b1; /* margin-left: 26px; */
}
.time-span {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #a2a8b1;
    margin-left: 15px;
}
@media only screen and (max-width: 480px) {
    #challenge-modal {
        top: 50px;
    }
    .chal-title {
        display: block;
        text-align: center;
        font-size: 18px;
        margin-top: 15px;
    }
    .chal-count-and-time {
        float: none;
        text-align: center;
        margin-top: 15px;
    }
}
.users-thumb-list {
    float: left;
    width: 25%;
}
.users-thumb-list > a img {
    max-width: 35px;
}
.users-thumb-list > a {
    margin-right: -20px;
}

.challenger-item {
    margin-left: 0 !important;
    position: relative;
    height: 220px !important;
    cursor: pointer;
}
